import { Badge } from "reactstrap";
import { CDataTooltip, CDataTooltipType } from "../CDataTooltip";
import classNames from "classnames";

export type PremiumChipProps = {
  className: string;
};

export const PremiumChip = (props: PremiumChipProps) => {
  const { className } = props;

  return (
    <CDataTooltip type={CDataTooltipType.Dark} title="Premium">
      <div className={classNames(className, "cursor-pointer")}>
        <Badge color="primary" size={16}>
          <i className="fa-solid fa-star" />
        </Badge>
      </div>
    </CDataTooltip>
  );
};
