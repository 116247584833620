import { useRef, useState } from "react";
import { Badge, Card, CardBody, CardText } from "reactstrap";
import { ButtonBase } from "@mui/material";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { getSettingsPageSubscriptionTab } from "../../utility/SubscriptionProvider";
import { CDataTooltip, CDataTooltipType } from "../CDataTooltip";
import { CDataTypography } from "../text/CDataTypography";
import { DriverIcon } from "./DriverIcon";
import { useAppSelector } from "src/redux/hooks";
import { IPartialDriver } from "src/models";
import { PremiumChip } from "./PremiumChip";
import "./DriverConnectionTile.scss";

export interface IDriverConnection {
  driver: IPartialDriver;
  onClick: () => void;
  isPremiumAndUserLacksFeature: boolean;
  isConnectionLimitReached?: boolean;
  isDataSourceLimitReached?: boolean;
  isPremiumDataSourceLimitReached?: boolean;
}

export const DriverConnectionTile = (props: IDriverConnection) => {
  const {
    driver,
    onClick,
    isPremiumAndUserLacksFeature = false,
    isConnectionLimitReached = false,
    isDataSourceLimitReached = false,
    isPremiumDataSourceLimitReached = false,
  } = props;

  const cardRef = useRef<HTMLDivElement>(null);

  const subscription = useAppSelector((state) => state.subscription);
  const availableDriverCategories =
    subscription.limits?.availableDriverCategories ?? [];
  const isNewPlan = availableDriverCategories.length > 0;

  let isPremiumChipDisplayed = false;
  let isDriverNormalAndOverLimit: boolean;
  let isDriverPremiumAndOverLimit = false;
  let isDriverCategoryNotInAllowedCategories = false;

  if (isNewPlan) {
    isPremiumChipDisplayed = driver.driverCategory === "C";
    isDriverNormalAndOverLimit =
      isDataSourceLimitReached && driver.driverCategory !== "C";
    isDriverPremiumAndOverLimit =
      isPremiumDataSourceLimitReached && driver.driverCategory === "C";
    isDriverCategoryNotInAllowedCategories =
      !availableDriverCategories.includes(driver.driverCategory ?? "") &&
      !isDriverNormalAndOverLimit &&
      !isDriverPremiumAndOverLimit;
  } else {
    isDriverNormalAndOverLimit = !driver.premium && isDataSourceLimitReached;
  }

  const isDriverDisabled =
    isPremiumAndUserLacksFeature ||
    isConnectionLimitReached ||
    isDriverNormalAndOverLimit ||
    isDriverPremiumAndOverLimit ||
    Boolean(isDriverCategoryNotInAllowedCategories);

  const { showTooltip, setShowTooltip, updateTooltip, getTooltipText } =
    useDriverTileTooltip(
      isDriverDisabled,
      driver.niceName!,
      isPremiumAndUserLacksFeature,
      isConnectionLimitReached,
      isDataSourceLimitReached,
      isDriverPremiumAndOverLimit,
      isDriverCategoryNotInAllowedCategories,
    );

  return (
    <CDataTooltip
      open={showTooltip}
      onOpen={() => updateTooltip(cardRef.current!)}
      onClose={() => setShowTooltip(false)}
      type={CDataTooltipType.Dark}
      title={getTooltipText()}
    >
      <div>
        <Card
          id={driver.driver}
          className={classNames("driver-card", {
            "disabled-opacity": isDriverDisabled,
          })}
        >
          <ButtonBase
            disableRipple
            className="driver-clickable-area"
            disabled={isDriverDisabled}
            onClick={() => {
              isDriverDisabled ? null : onClick();
            }}
          >
            <div className="driver-tile-icon-container">
              <DriverIcon driver={driver.driver!} />
            </div>
            <CardBody className="card-body">
              <CardText
                id={`row-${driver}`}
                className="driver-card-title"
                tag="div"
              >
                <div ref={cardRef}>
                  <CDataTypography
                    variant="typography-variant-body-medium"
                    color="typography-color-text-muted"
                  >
                    {driver.niceName}
                  </CDataTypography>
                </div>
              </CardText>
            </CardBody>
          </ButtonBase>
          <div>
            <div className="action-card-container d-flex">
              <Badge
                id="beta-badge"
                hidden={!driver.beta}
                color=""
                className="driver-beta-badge"
              >
                Beta
              </Badge>
              {isPremiumChipDisplayed && <PremiumChip className="me-2" />}
            </div>
          </div>
        </Card>
      </div>
    </CDataTooltip>
  );
};

/** A small custom hook that handles the layered logic for tooltips on driver tiles. */
const useDriverTileTooltip = (
  disabled: boolean,
  niceName: string,
  isPremiumAndUserLacksFeature?: boolean,
  isConnectionLimitReached?: boolean,
  isDataSourceLimitReached?: boolean,
  isDriverPremiumAndOverLimit?: boolean,
  isDriverCategoryNotInAllowedCategories?: boolean,
) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const navigate = useNavigate();

  const updateTooltip = (element: {
    offsetHeight: number;
    scrollHeight: number;
    offsetWidth: number;
    scrollWidth: number;
  }) => {
    // If the driver is disabled, we need the tooltip to show on hover no matter what. If it's not disabled, check for overflow.
    if (disabled) {
      setShowTooltip(true);
    } else {
      const hasOverflowingChildren: boolean =
        element.offsetHeight < element.scrollHeight ||
        element.offsetWidth < element.scrollWidth;

      setShowTooltip(hasOverflowingChildren);
    }
  };

  const getTooltipText = () => {
    // If the driver isn't disabled, we would only need the full driver name to show in the tooltip.
    if (!disabled) return niceName;

    // If the driver is disabled, figure out why and set the tooltip accordingly.
    const upgradeLink = (
      <a
        className="upgrade-link"
        onClick={() =>
          navigate("/settings?defaultTab=" + getSettingsPageSubscriptionTab())
        }
      >
        Upgrade
      </a>
    );

    if (isConnectionLimitReached) {
      return (
        <span>
          You&apos;ve reached your Connection limit for this Data Source.{" "}
          {upgradeLink} your plan to add more.
        </span>
      );
    } else if (
      isDriverCategoryNotInAllowedCategories ||
      isPremiumAndUserLacksFeature
    ) {
      return (
        <span>
          This data source is not available with your subscription. Upgrade your
          plan or contact sales@cdata.com for assistance.
        </span>
      );
    } else if (isDataSourceLimitReached || isDriverPremiumAndOverLimit) {
      return (
        <span>
          You have reached your subscription limit for this data source tier.
          Contact sales@cdata.com to add more.
        </span>
      );
    }
  };

  return { showTooltip, setShowTooltip, updateTooltip, getTooltipText };
};
