import { Badge } from "reactstrap";
import { DriverConnectionTile } from "./DriverConnectionTile";
import { IPartialDriver } from "src/models";

export function getBaseIconUrl(): string {
  if (window.location.hostname === "cloud.cdata.com")
    return "https://cdata-cloudprod.azureedge.net/driver-icons";
  else if (window.location.hostname === "staging.clouddataos.com")
    return "https://cdata-cnctstg.azureedge.net/driver-icons";
  // Dev and Local
  else return "https://cdata-cncteast.azureedge.net/driver-icons";
}

export function getDriverIcon(driver: string, className: string): JSX.Element {
  return (
    <img
      src={`${getBaseIconUrl()}/${driver}.svg`}
      alt=""
      className={className}
    />
  );
}

export function getBetaIcon(isBeta: boolean, className: string): JSX.Element {
  return (
    <span hidden={!isBeta}>
      <Badge id="beta-badge" color="" className={className}>
        Beta
      </Badge>
    </span>
  );
}

export function getInitialIcon(driver: string, className: string): JSX.Element {
  return (
    <img
      src={`${getBaseIconUrl()}/${driver}.svg`}
      alt=""
      className={className}
    />
  );
}

export function getBetaDriverIcon(
  isBeta: boolean,
  driver: string,
  className: string,
): JSX.Element {
  return (
    <div className="outer-initial-connection">
      <Badge
        hidden={!isBeta}
        id="beta-badge"
        className="badge-beta-initial-setup"
        color=""
      >
        Beta
      </Badge>
      <img
        src={`${getBaseIconUrl()}/${driver}.svg`}
        className={className}
        alt=""
      />
    </div>
  );
}

export function getBetaDriverIconCard(
  driver: IPartialDriver,
  onClick: () => void,
  isPremiumAndUserLacksFeature: boolean,
  isConnectionLimitReached?: boolean,
  isDataSourceLimitReached?: boolean,
  isPremiumDataSourceLimitReached?: boolean,
): JSX.Element {
  return (
    <DriverConnectionTile
      driver={driver}
      onClick={onClick}
      isPremiumAndUserLacksFeature={isPremiumAndUserLacksFeature}
      isConnectionLimitReached={isConnectionLimitReached}
      isDataSourceLimitReached={isDataSourceLimitReached}
      isPremiumDataSourceLimitReached={isPremiumDataSourceLimitReached}
    />
  );
}
