import ApiConnectorLogo from "../../assets/img/Rest.svg?react";
import { getBaseIconUrl } from "./DriverIconFactory";
import "./DriverIcon.scss";

type DriverIconProps = {
  driver: string;
};

export const DriverIcon = (props: DriverIconProps) => {
  const { driver } = props;

  return driver === "ApiConnector" ? (
    <ApiConnectorLogo
      className="driver-tile-icon card-img-top img-thumbnail"
      aria-description="API Connector"
    />
  ) : (
    <img
      src={`${getBaseIconUrl()}/${driver}.svg`}
      className="driver-tile-icon card-img-top img-thumbnail"
      alt={`${driver} icon`}
    />
  );
};
